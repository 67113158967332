/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Button, Grid, Typography, styled } from '@mui/material';

export const TitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
}));

export const HomeGrid = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

export const ButtonGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

export const PrimaryButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});
