/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { styled, Grid, Typography, TextField } from '@mui/material';

export const SubmitButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(9),
}));
export const FieldValueGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1),
  display: 'flex',
}));
export const BoxGrid = styled(Grid)(({ theme }) => ({
  direction: 'row',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  spacing: theme.spacing(2),
  marginTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
}));
export const KeyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
export const StyledFieldGrid = styled(Grid)(({ theme }) => ({}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 300,
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
