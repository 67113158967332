/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BottomMarginBox } from './roles.page.styled';
import * as PATHS from '../../constants/path';
import PageBackground from '../../components/app.pageBackground';
import ItemsMenu from '../../components/itemsMenu/itemsMenu.component';
import DataGridTable from '../../components/datagridTable';
import ActionsDialog from '../../components/actionsDialog';
import { useApiContext } from '../../services/apiContext';
import LinearLoading from '../../components/linearLoading';

const RolesPage = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const { getRoles, roles, deleteRoleById, loading } = useApiContext();

  useEffect(() => {
    getRoles();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDeleteRoleDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteRoleDialog = (e, row) => {
    setOpenDeleteDialog(false);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (row) => {
    navigate(`${PATHS.ROLES_PAGE}/${row.id}`);
  };

  const handleDeleteRole = async () => {
    try {
      await deleteRoleById(deleteRoleById);
      getRoles();
      handleCloseDeleteRoleDialog();
    } catch (error) {
      console.error('Delete Error:', error);
    }
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip title={t('rolesPage.tooltips.action')} arrow>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClick(e);
            }}
            disableRipple
          >
            <MoreHorizOutlined />
          </Button>
        </Tooltip>
        <ItemsMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleDelete={handleOpenDeleteRoleDialog}
        />
      </>
    );
  };
  const columns = [
    {
      field: 'name',
      headerName: t('rolesPage.columns.name'),
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'description',
      headerName: t('rolesPage.columns.description'),
      minWidth: 300,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'actions',
      headerName: t('rolesPage.columns.actions'),
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  const rolesData = roles ? roles : [];

  return (
    <PageBackground>
      <BottomMarginBox>
        {loading ? (
          <LinearLoading />
        ) : (
          <DataGridTable
            row={rolesData}
            column={columns}
            pageSize={5}
            rowHeightAuto
            handleRow={handleRowClick}
            header={t('rolesPage.header')}
            isAddButtonNeeded
            path={PATHS.ADD_ROLE_PAGE}
          />
        )}

        <ActionsDialog
          openDialog={openDeleteDialog}
          action={t('rolesPage.actionsDialog.action')}
          type={t('rolesPage.actionsDialog.type')}
          closeDialog={handleCloseDeleteRoleDialog}
          handleAction={handleDeleteRole}
        />
      </BottomMarginBox>
    </PageBackground>
  );
};

export default RolesPage;
