/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledDialogAction } from './actionsDialog.styled';

const ActionsDialog = ({
  openDialog,
  action,
  closeDialog,
  handleAction,
  type,
}) => {
  const { t } = useTranslation();

  const sentenceCaseAction =
    action?.charAt(0).toUpperCase() + action?.slice(1).toLowerCase();

  return (
    <Dialog open={openDialog}>
      <DialogContent>
        <Alert severity="warning">
          <AlertTitle>
            {t('actionsDialog.alertTitle', {
              action: action.toLowerCase(),
              type: type.toLowerCase(),
            })}
          </AlertTitle>
          <AlertTitle>
            {t('actionsDialog.alertSubtitle', { action: action.toLowerCase() })}
          </AlertTitle>
        </Alert>
      </DialogContent>
      <StyledDialogAction>
        <Button variant="outlined" size="small" onClick={closeDialog}>
          {t('actionsDialog.buttons.cancel')}
        </Button>
        <Button variant="contained" size="small" onClick={handleAction}>
          {t('actionsDialog.buttons.action', {
            action: sentenceCaseAction,
          })}
        </Button>
      </StyledDialogAction>
    </Dialog>
  );
};

ActionsDialog.propTypes = {
  openDialog: PropTypes.bool,
  action: PropTypes.string,
  closeDialog: PropTypes.func,
  handleAction: PropTypes.func,
  type: PropTypes.string,
};
export default ActionsDialog;
