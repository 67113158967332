/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Button, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { Menu } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { FlexBox } from './app.navbar.styled';

/**
 * User details for mobile screens ; smaller than md screens
 */
const MobileUserDetails = ({ handleSignIn, handleMenuOpen }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <FlexBox>
      {auth.isAuthenticated ? (
        <>
          <IconButton onClick={handleMenuOpen}>
            <Menu />
          </IconButton>
        </>
      ) : (
        <Button onClick={handleSignIn} variant="contained">
          {t('navbar.button.signin')}
        </Button>
      )}
    </FlexBox>
  );
};

MobileUserDetails.propTypes = {
  handleSignIn: PropTypes.func,
  handleMenuOpen: PropTypes.func,
};

export default MobileUserDetails;
