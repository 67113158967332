/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { StyledCard } from './appContentCard.styled';

/**
 * wraps content in a card component as per theme
 * @param {*} props
 */
const AppContentCard = (props) => {
  return (
    <StyledCard>
      <CardContent>{props.children}</CardContent>
    </StyledCard>
  );
};

AppContentCard.propTypes = {
  children: PropTypes.node,
};

export default AppContentCard;
