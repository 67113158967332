/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledButtonGroup,
  StyledCancelButton,
  StyledSubmitButton,
} from './submitButtonGroup.styled';

const SubmitButtonGroup = ({
  onCancel,
  onSubmit,
  hasSubmitButton = true,
  hasCancelButton = true,
  submitButtonText = 'Save',
  cancelButtonText = 'Cancel',
}) => {
  return (
    <StyledButtonGroup variant="outlined" aria-label="button group">
      {hasCancelButton && (
        <StyledCancelButton onClick={onCancel}>
          {cancelButtonText}
        </StyledCancelButton>
      )}
      {hasSubmitButton && (
        <StyledSubmitButton onClick={onSubmit}>
          {submitButtonText}
        </StyledSubmitButton>
      )}
    </StyledButtonGroup>
  );
};

SubmitButtonGroup.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  hasSubmitButton: PropTypes.bool,
  hasCancelButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

export default SubmitButtonGroup;
