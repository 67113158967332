/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Route, Routes } from 'react-router-dom';
import * as PATHS from '../constants/path';
import HomePage from '../pages/homePage';
import FileCollectionsPage from '../pages/fileCollectionsPage';
import RolesPage from '../pages/rolesPage';
import NewFileCollectionFormData from '../pages/addFileCollectionPage/addFileCollection.page';
import FileCollectionPage from '../pages/fileCollectionPage';
import AddDataSourcePage from '../pages/addDataSourcePage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.HOME_PAGE} element={<HomePage />} />
      <Route
        path={PATHS.FILE_COLLECTIONS_PAGE}
        element={<FileCollectionsPage />}
      />
      <Route path={PATHS.ROLES_PAGE} element={<RolesPage />} />
      <Route
        path={PATHS.ADD_FILE_COLLECTION_PAGE}
        element={<NewFileCollectionFormData />}
      />
      <Route
        path={`${PATHS.FILE_COLLECTIONS_PAGE}/:fileCollectionId`}
        element={<FileCollectionPage />}
      />
      <Route
        path={`${PATHS.FILE_COLLECTIONS_PAGE}/:fileCollectionId/${PATHS.ADD_DATA_SOURCE_PAGE}`}
        element={<AddDataSourcePage />}
      />
    </Routes>
  );
};

export default AppRoutes;
