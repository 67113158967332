import { LinearProgress } from '@mui/material';
import AppContentCard from '../app.contentCard';

const LinearLoading = () => {
  return (
    <AppContentCard>
      <LinearProgress />
    </AppContentCard>
  );
};

export default LinearLoading;
