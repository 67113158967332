/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const {
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_SCOPE,
  REACT_APP_AUTH_REDIRECT_URL,
  REACT_APP_AUTH_LOGOUT_REDIRECT_URL,
} = process.env;

export const AUTHORITY = REACT_APP_AUTH_AUTHORITY;
export const CLIENT_ID = REACT_APP_AUTH_CLIENT_ID;
export const REDIRECT_URL = REACT_APP_AUTH_REDIRECT_URL;
export const LOGOUT_REDIRECT_URL = REACT_APP_AUTH_LOGOUT_REDIRECT_URL;
export const SCOPE = REACT_APP_AUTH_SCOPE;
