/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import speciphicTheme from './speciphicTheme/theme';

const applicationTheme = (themeName) => {
  switch (themeName) {
    case 'speciphic':
      return speciphicTheme;
    default:
      return speciphicTheme;
  }
};

export default applicationTheme;
