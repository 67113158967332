/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import applicationTheme from './themes/appTheme';
import AppNavbar from './components/app.navbar';
import { useRef } from 'react';
import useSizing from './hooks/useSizing';
import { ScrollBox } from './App.styled';
import AppFooter from './components/app.footer';
import AppRoutes from './routes/appRoutes';
import ErrorFallbackPage from './pages/errorFallbackPage';

const App = () => {
  const currentTheme = applicationTheme();

  const handleApplicationError = (error, info) => {
    console.error('An error has occurred in the application.');
    console.error(error, info);
  };

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const { height: headerHeight } = useSizing(headerRef);
  const { height: footerHeight } = useSizing(footerRef);

  return (
    <ThemeProvider theme={currentTheme}>
      <ErrorBoundary
        FallbackComponent={ErrorFallbackPage}
        onError={handleApplicationError}
      >
        <Box>
          <CssBaseline />
          <AppNavbar ref={headerRef} />
          <ScrollBox headerheight={headerHeight} footerheight={footerHeight}>
            <AppRoutes />
          </ScrollBox>
          <AppFooter ref={footerRef} />
        </Box>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
