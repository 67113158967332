/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, Chip, alpha, styled } from '@mui/material';

export const RoundIcon = styled(Box)({
  borderRadius: '50%',
});

export const StyledChip = styled(Chip)(({ theme, backgroundColor }) => ({
  borderRadius: 0,
  padding: theme.spacing(1),
  fontSize: 14,
  margin: theme.spacing(2),
  backgroundColor: alpha(backgroundColor, 0.2),
}));
