/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Autocomplete, styled } from '@mui/material';

export const StyledAutocomplete = styled(Autocomplete)({
  width: 300,
  borderRadius: 0,
});
