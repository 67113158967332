/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Box, Typography, styled } from '@mui/material';

export const PaddingTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

export const CenteredBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
