/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import axios from 'axios';
import { ADMIN_API_URL, TENANT_ID } from '../config/apiConstants';
import { getUserFromSession } from './user';
import axiosRetry from 'axios-retry';

const ADMIN_API_BASE_URL = ADMIN_API_URL;

const instance = axios.create({
  baseURL: `${ADMIN_API_BASE_URL}/api/tenants/${TENANT_ID}`,
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  shouldRetry: (error) => {
    return error.response && error.response.status >= 500;
  },
});

const user = getUserFromSession();
const token = user?.access_token;

instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const adminApi = {
  getFileCollections: () => instance.get('/fileCollections'),
  addFileCollection: (formData) => instance.post('/fileCollections', formData),
  deleteFileCollectionById: (id) => instance.delete(`/fileCollections/${id}`),
  getRoles: () => instance.get('/roles'),
  deleteRoleById: (id) => instance.delete(`roles/${id}`),
  getFileCollectionById: (fileCollectionId) =>
    instance.get(`/fileCollections/${fileCollectionId}`),
  getDataSources: (fileCollectionId) =>
    instance.get(`/fileCollections/${fileCollectionId}/dataSources`),
  deleteDataSourceById: (fileCollectionId, dataSourceId) =>
    instance.get(
      `/fileCollections/${fileCollectionId}/dataSources/${dataSourceId}`,
    ),
  addDataSource: (formData, fileCollectionID) =>
    instance.post(
      `/fileCollections/${fileCollectionID}/dataSources/`,
      formData,
    ),
};

export default adminApi;
