/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import { Grid, Icon, Button, styled, Typography } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Search, Clear } from '@mui/icons-material';

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.whiteBackground.main,
  borderRadius: 0,
  margin: theme.spacing(3),
  boxShadow: `0px 4px 4px 0px ${theme.palette.secondary.dark}25`,
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.dark,
    fontSize: 16,
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },
  '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '&.MuiDataGrid-root .MuiDataGrid-iconButtonContainer:focus': {
    outline: 'none',
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
    visibility: 'visible',
  },
  '&.MuiDataGrid-toolbarContainer': {
    color: theme.palette.primary.main,
  },
  '.MuiDataGrid-row': {
    color: theme.palette.secondary.dark,
  },
}));

export const StyledToolbarGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledFileUploadIcon = styled(Icon)(({ theme }) => ({
  height: 20,
  marginRight: theme.spacing(0.5),
}));

export const StyledFileUploadButton = styled(Button)(({ theme }) => ({
  float: 'right',
  [theme.breakpoints.up('xs')]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: theme.spacing(0),
  },
}));

export const StyledSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: theme.spacing(0),
}));

export const StyledClearIcon = styled(Clear)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(0),
}));

export const HeaderTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));

export const StyledGridToolbarQuickFilter = styled(GridToolbarQuickFilter)(
  ({ theme }) => ({
    paddingRight: theme.spacing(2),
    width: 'auto',
  }),
);
