/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FlexdayLogo } from '../../assets/flexday-logo.svg';
import {
  FlexdayContentBox,
  FooterBox,
  FooterPaper,
  LogoBox,
  StyledTypography,
} from './app.footer.styled';

const AppFooter = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <FooterBox ref={ref}>
      <FooterPaper elevation={3}>
        <FlexdayContentBox>
          <StyledTypography variant="body1">
            {t('footer.poweredBy')}
          </StyledTypography>
          <LogoBox>
            <FlexdayLogo sx={{ alignContent: 'center' }} />
          </LogoBox>
        </FlexdayContentBox>
      </FooterPaper>
    </FooterBox>
  );
});

AppFooter.displayName = 'AppFooter';

export default AppFooter;
