/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const HOME_PAGE = '/';
export const FILE_COLLECTIONS_PAGE = '/file-collections';
export const ADD_FILE_COLLECTION_PAGE = `${FILE_COLLECTIONS_PAGE}/create-new`;
export const ROLES_PAGE = '/roles';
export const ADD_ROLE_PAGE = `${ROLES_PAGE}/create-new`;
export const ADD_DATA_SOURCE_PAGE = `/data-sources/create-new`;
