/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Typography } from '@mui/material';
import { StyledLink } from './app.breadcrumb.styled';
import * as PATHS from '../../constants/path';

/**
 * navigation links
 * @returns links for to-and-fro navigation
 */
const AppBreadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathnames = location.pathname.split('/').filter((x) => x);

  /**
   * @param {*} segment string path segment
   * @returns formatted path name in PascalCase
   */
  const formatPathSegment = (segment) => {
    return segment
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // returns null for home page
  if (pathnames.length === 0) {
    return null;
  }

  return (
    <Breadcrumbs
      separator="\"
      aria-label="breadcrumb"
      sx={{ width: '100%', marginLeft: 4 }}
    >
      <StyledLink to={PATHS.HOME_PAGE}>{t('breadcrumbs.home')}</StyledLink>
      {pathnames.map((pathname, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        const last = index === pathnames.length - 1;
        const formattedPath = formatPathSegment(pathname);

        return last ? (
          <Typography key={to} color="primary">
            {formattedPath}
          </Typography>
        ) : (
          <StyledLink to={to} key={to}>
            {formattedPath}
          </StyledLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumbs;
