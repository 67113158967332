/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
export const DATA_SOURCE_DEFAULT_VALUES = {
  DELAY: 60,
  MAX_MESSAGES_PER_POLL: 200,
  TEXT_EXTRACTION_STRATEGY: 'SIMPLE_EXTRACTION',
  NORMALIZE_NEW_LINES: Boolean(true),
  NORMALIZE_WHITE_SPACES: Boolean(true),
  REMOVE_HEADER_FOOTER: Boolean(true),
  HEADER_FOOTER_MIN_CHARS: 3,
  HEADER_FOOTER_MAX_CHARS: 30,
  TEXT_CHUNKING_STRATEGY: 'PASSAGE',
  MIN_CHUNK_WORDS: 30,
  MAX_CHUNK_WORDS: 300,
  RESPECT_SENTENCE_BOUNDARY: Boolean(false),
  ERROR_HANDLING_STRATEGY: 'LOG_MARK_FORGET',
  MAX_NO_OF_TRIES: 1,
};
