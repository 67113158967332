/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material';
import palette from './palette';
import typography from '../typography';
import fontWeights from '../fontWeights';
import { muiButton } from '../components/muiButton';
import { muiOutlinedInput } from '../components/muiOutlinedInput';

export const themeOptions = {
  fontWeights,
  palette,
  typography,
  components: {
    MuiButton: muiButton,
    MuiOutlinedInput: muiOutlinedInput,
  },
};

const theme = createTheme(themeOptions);

export default theme;
