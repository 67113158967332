/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.
 
 */
import React from 'react';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  HeaderTypography,
  StyledDataGrid,
  StyledFileUploadButton,
  StyledGridToolbarQuickFilter,
  StyledSearchIcon,
  StyledToolbarGrid,
} from './datagridTable.styled';

const DataGridTable = ({
  row,
  column,
  pageSize,
  handleRow,
  header,
  sortingOrder,
  sortingField,
  isAddButtonNeeded,
  rowHeightAuto,
  path,
  checkboxSelection,
}) => {
  const { t } = useTranslation();

  const CustomToolbar = () => {
    return (
      <Box>
        <StyledToolbarGrid container spacing={2}>
          <Grid item xs={12} sm={6} md={'auto'}>
            <HeaderTypography variant="h3">{header}</HeaderTypography>
          </Grid>
          <Grid container item xs={12} sm={6} md={'auto'}>
            <Grid item xs={12} sm={6} md={'auto'}>
              <StyledGridToolbarQuickFilter
                placeholder={t('datagridTable.search')}
              />
            </Grid>
            {isAddButtonNeeded && (
              <Grid item xs={12} sm={6} md={'auto'}>
                <StyledFileUploadButton
                  variant="contained"
                  size="medium"
                  component={Link}
                  to={path}
                  startIcon={<Add />}
                >
                  {t('datagridTable.addButton')}
                </StyledFileUploadButton>
              </Grid>
            )}
          </Grid>
        </StyledToolbarGrid>
      </Box>
    );
  };

  return (
    <StyledDataGrid
      autoHeight
      pageSize={pageSize}
      initialState={{
        sorting: {
          sortModel: [{ field: sortingField, sort: sortingOrder }],
        },
      }}
      rows={row}
      checkboxSelection={checkboxSelection}
      columns={column}
      disableColumnMenu
      disableSelectionOnClick
      onRowClick={handleRow}
      slots={{
        toolbar: CustomToolbar,
        quickFilterIcon: StyledSearchIcon,
      }}
      getRowHeight={() => (rowHeightAuto ? 'auto' : null)}
    />
  );
};
DataGridTable.propTypes = {
  row: PropTypes.array,
  column: PropTypes.array,
  pageSize: PropTypes.number,
  handleRow: PropTypes.func,
  header: PropTypes.string,
  sortingOrder: PropTypes.string,
  sortingField: PropTypes.string,
  isAddButtonNeeded: PropTypes.bool,
  rowHeightAuto: PropTypes.bool,
  path: PropTypes.any,
  checkboxSelection: PropTypes.bool,
};

export default DataGridTable;
