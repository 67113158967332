/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import { Box, Typography, Grid, TextField } from '@mui/material';
import OptionsDropdown from '../../components/optionsDropdown';
import RadioButtonGroup from '../../components/radioButton';
import { useTranslation } from 'react-i18next';
import {
  BoxGrid,
  KeyTypography,
  StyledTextField,
  StyledFieldGrid,
  GridContainer,
  FieldValueGrid,
} from './addDataSource.styled';
import { DATA_SOURCE_DEFAULT_VALUES as DEFAULT } from './dataSource.constants';

const DataSourceProcessingParameters = ({
  maxMessagesPerPoll,
  handleMaxMessagesPerPollChange,
  normalizeNewLines,
  handleNormalizeNewLinesChange,
  removeHeaderFooter,
  handleRemoveHeaderFooterChange,
  headerFooterMinChars,
  handleHeaderFooterMinCharsChange,
  headerFooterMaxChars,
  handleHeaderFooterMaxCharsChange,
  normalizeWhiteSpaces,
  handleNormalizeWhiteSpacesChange,
  minChunkWords,
  handleMinChunkWordsChange,
  maxChunkWords,
  handleMaxChunkWordsChange,
  respectSentenceBoundary,
  handleRespectSentenceBoundaryChange,
  errorHandlingStrategy,
  handleErrorHandlingStrategyChange,
  maxNoOfTries,
  handleMaxNoOfTriesChange,
  textExtractionStrategy,
  handleTextExtractionStrategyChange,
  textChunkingStrategy,
  handleTextChunkingStrategyChange,
}) => {
  const { t } = useTranslation();

  return (
    <GridContainer>
      <BoxGrid>
        <Typography variant="h4" gutterBottom>
          {t('addDataSourcePage.processingParametersHeading')}
        </Typography>
      </BoxGrid>
      <BoxGrid container spacing={2}>
        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.maxMessagesPerPoll')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={maxMessagesPerPoll}
                onChange={handleMaxMessagesPerPollChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.normalizeNewLines')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <RadioButtonGroup
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' },
                ]}
                defaultValue={DEFAULT.NORMALIZE_NEW_LINES}
                onChange={handleNormalizeNewLinesChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.textExtractionStrategy')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <OptionsDropdown
                options={[
                  { value: 'SIMPLE_EXTRACTION', label: 'Simple Extraction' },
                ]}
                defaultValue={DEFAULT.TEXT_EXTRACTION_STRATEGY}
                onChange={handleTextExtractionStrategyChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.removeHeaderFooter')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <RadioButtonGroup
                defaultValue={DEFAULT.REMOVE_HEADER_FOOTER}
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' },
                ]}
                onChange={handleRemoveHeaderFooterChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.headerFooterMinChars')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                defaultValue={headerFooterMinChars}
                onChange={handleHeaderFooterMinCharsChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.headerFooterMaxChars')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={headerFooterMaxChars}
                onChange={handleHeaderFooterMaxCharsChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.textChunkingStrategy')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid xs={12} sm={8.3}>
              <OptionsDropdown
                options={[
                  { value: 'PASSAGE', label: 'Passage' },
                  { value: 'SENTENCE', label: 'Sentence' },
                  { value: 'WORD', label: 'Word' },
                  { value: 'NONE', label: 'None' },
                ]}
                onChange={handleTextChunkingStrategyChange}
                defaultValue={DEFAULT.TEXT_CHUNKING_STRATEGY}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.minChunkWords')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={minChunkWords}
                onChange={handleMinChunkWordsChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.maxChunkWords')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={maxChunkWords}
                onChange={handleMaxChunkWordsChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.normalizeWhiteSpaces')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <RadioButtonGroup
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' },
                ]}
                onChange={handleNormalizeWhiteSpacesChange}
                defaultValue={DEFAULT.NORMALIZE_WHITE_SPACES}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.respectSentenceBoundary')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <RadioButtonGroup
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' },
                ]}
                onChange={handleRespectSentenceBoundaryChange}
                defaultValue={DEFAULT.RESPECT_SENTENCE_BOUNDARY}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.errorHandlingStrategy')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <OptionsDropdown
                options={[
                  { value: 'LOG_MARK_FORGET', label: 'LOG_MARK_FORGET' },
                  {
                    value: 'LOG_MARK_RETRY_NEXT',
                    label: 'LOG_MARK_RETRY_NEXT',
                  },
                ]}
                onChange={handleErrorHandlingStrategyChange}
                defaultValue={DEFAULT.ERROR_HANDLING_STRATEGY}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.maxNoOfTries')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={maxNoOfTries}
                onChange={handleMaxNoOfTriesChange}
                defaultValue={DEFAULT.MAX_NO_OF_TRIES}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>
      </BoxGrid>
    </GridContainer>
  );
};

export default DataSourceProcessingParameters;
