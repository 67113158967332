/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './App.css';
import './i18n';
import {
  AUTHORITY,
  CLIENT_ID,
  REDIRECT_URL,
  LOGOUT_REDIRECT_URL,
  SCOPE,
} from './config/authConstants';
import { ApiProvider } from './services/apiContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

const oidcConfig = {
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  scope: SCOPE,
  redirect_uri: REDIRECT_URL,
  post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
};

const handleSignin = (state) => {
  if (state?.state !== undefined) {
    window.location.replace(atob(state.state));
  } else {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={handleSignin}>
      <ApiProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApiProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
