/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
   * Unauthorized copying of this file, via any medium is strictly prohibited
   * Proprietary and confidential
   * See file LICENSE.txt for full license details.
   
   */

import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusChip from '../../components/statusChip';

const GetDataSourceColumns = ({ actionColumn }) => {
  const { t } = useTranslation();

  const columns = [
    {
      field: 'name',
      headerName: t(
        'fileCollectionPage.dataSourceConnectedTo.gridHeaderColumnName.name',
      ),
      width: 300,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'description',
      headerName: t(
        'fileCollectionPage.dataSourceConnectedTo.gridHeaderColumnName.description',
      ),
      width: 320,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'endpointType',
      headerName: t(
        'fileCollectionPage.dataSourceConnectedTo.gridHeaderColumnName.type',
      ),
      width: 180,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'active',
      headerName: t(
        'fileCollectionPage.dataSourceConnectedTo.gridHeaderColumnName.status',
      ),
      minWidth: 100,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => {
        const variant = params.value ? 'success' : 'error';
        return <StatusChip variant={variant} />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return columns;
};

export default GetDataSourceColumns;
