/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import React from 'react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CenteredTypography,
  PaddingTypography,
  SpacedAlert,
} from './errorFallback.page.styled';
import PageBackground from '../../components/app.pageBackground';
import { ReactComponent as SpeciphicLogo } from '../../assets/speciphic-logo.svg';
import AppContentCard from '../../components/app.contentCard';

const ErrorFallbackPage = ({ error }) => {
  const { t } = useTranslation();
  return (
    <PageBackground>
      <AppContentCard>
        <div>
          <SpeciphicLogo
            width="100%"
            height="100%"
            style={{
              maxWidth: '40%',
            }}
          />
          <Divider />
          <CenteredTypography variant="h2">
            {t('errorFallbackPage.errorMsg')}
          </CenteredTypography>
          <SpacedAlert severity="warning">{error.message}</SpacedAlert>

          <PaddingTypography variant="caption">
            {t('errorFallbackPage.alertMsg', {
              adminEmail: 'admin@flexday.ai',
            })}
          </PaddingTypography>
        </div>
      </AppContentCard>
    </PageBackground>
  );
};

ErrorFallbackPage.propTypes = {
  error: PropTypes.node,
};

export default ErrorFallbackPage;
