/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import React, { useState } from 'react';
import { Typography, Grid } from '@mui/material';
import PageBackground from '../../components/app.pageBackground';
import AppContentCard from '../../components/app.contentCard';
import SubmitButtonGroup from '../../components/submitButtonGroup';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import adminApi from '../../services/apiConfig';
import { BoxGrid, SubmitButtonGrid } from './addDataSource.styled';
import { useTranslation } from 'react-i18next';
import DataSourceMeta from './dataSourceMeta.component';
import DataSourceConfigurationDetails from './dataSourceConfigurationDetails.component';
import DataSourceProcessingParameters from './dataSourceProcessingParameters.component';
import { DATA_SOURCE_DEFAULT_VALUES as DEFAULT } from './dataSource.constants';
import SnackBar from '../../components/snackbar';
import * as PATHS from '../../constants/path';

const AddDataSourcePage = () => {
  const { t } = useTranslation();
  const { fileCollectionId } = useParams();
  const navigate = useNavigate();

  const [dataSourceName, setDataSourceName] = useState('');
  const [dataSourceDescription, setDataSourceDescription] = useState('');
  const [endpointType, setEndpointType] = useState();
  const [isActive, setIsActive] = useState(false);
  const [bucketName, setBucketName] = useState('');
  const [region, setRegion] = useState('');
  const [accessKey, setAccessKey] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [delay, setDelay] = useState(DEFAULT.DELAY);
  const [maxMessagesPerPoll, setMaxMessagesPerPoll] = useState(
    DEFAULT.MAX_MESSAGES_PER_POLL,
  );
  const [textExtractionStrategy, setTextExtractionStrategy] = useState(
    DEFAULT.TEXT_EXTRACTION_STRATEGY,
  );
  const [normalizeNewLines, setNormalizeNewLines] = useState(
    DEFAULT.NORMALIZE_NEW_LINES,
  );
  const [normalizeWhiteSpaces, setNormalizeWhiteSpaces] = useState(
    DEFAULT.NORMALIZE_WHITE_SPACES,
  );
  const [removeHeaderFooter, setRemoveHeaderFooter] = useState(
    DEFAULT.REMOVE_HEADER_FOOTER,
  );
  const [headerFooterMinChars, setHeaderFooterMinChars] = useState(
    DEFAULT.HEADER_FOOTER_MIN_CHARS,
  );
  const [headerFooterMaxChars, setHeaderFooterMaxChars] = useState(
    DEFAULT.HEADER_FOOTER_MAX_CHARS,
  );
  const [textChunkingStrategy, setTextChunkingStrategy] = useState(
    DEFAULT.RESPECT_SENTENCE_BOUNDARY,
  );
  const [minChunkWords, setMinChunkWords] = useState(DEFAULT.MIN_CHUNK_WORDS);
  const [maxChunkWords, setMaxChunkWords] = useState(DEFAULT.MAX_CHUNK_WORDS);
  const [respectSentenceBoundary, setRespectSentenceBoundary] = useState(
    DEFAULT.RESPECT_SENTENCE_BOUNDARY,
  );
  const [errorHandlingStrategy, setErrorHandlingStrategy] = useState(
    DEFAULT.ERROR_HANDLING_STRATEGY,
  );
  const [maxNoOfTries, setMaxNoOfTries] = useState(DEFAULT.MAX_NO_OF_TRIES);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [showAccessKey, setShowAccessKey] = useState(false);

  const handleDataSourceNameChange = (e) => {
    setDataSourceName(e.target.value);
  };

  const handleDataSourceDescriptionChange = (e) => {
    setDataSourceDescription(e.target.value);
  };

  const handleIsActiveChange = (e) => {
    const booleanValue = e.target.value === 'true';
    setIsActive(booleanValue);
  };

  const handleEndpointTypeChange = (option) => {
    setEndpointType(option?.value);
  };

  const handleBucketNameChange = (e) => {
    setBucketName(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleAccessKeyChange = (e) => {
    setAccessKey(e.target.value);
  };

  const handleSecretKeyChange = (e) => {
    setSecretKey(e.target.value);
  };

  const handleDelayChange = (e) => {
    setDelay(e.target.value);
  };

  const handleMaxMessagesPerPollChange = (e) => {
    setMaxMessagesPerPoll(parseInt(e.target.value));
  };

  const handleTextExtractionStrategyChange = (option) => {
    setTextExtractionStrategy(option?.value);
  };

  const handleNormalizeNewLinesChange = (e) => {
    const booleanValue = e.target.value === 'true';
    setNormalizeNewLines(booleanValue);
  };

  const handleRemoveHeaderFooterChange = (e) => {
    const booleanValue = e.target.value === 'true';
    setRemoveHeaderFooter(booleanValue);
  };

  const handleHeaderFooterMinCharsChange = (e) => {
    setHeaderFooterMinChars(parseInt(e.target.value));
  };

  const handleHeaderFooterMaxCharsChange = (e) => {
    setHeaderFooterMaxChars(parseInt(e.target.value));
  };

  const handleTextChunkingStrategyChange = (option) => {
    setTextChunkingStrategy(option?.value);
  };

  const handleNormalizeWhiteSpacesChange = (e) => {
    const booleanValue = e.target.value === 'true';
    setNormalizeWhiteSpaces(booleanValue);
  };

  const handleMinChunkWordsChange = (e) => {
    setMinChunkWords(parseInt(e.target.value));
  };

  const handleMaxChunkWordsChange = (e) => {
    setMaxChunkWords(parseInt(e.target.value));
  };

  const handleRespectSentenceBoundaryChange = (e) => {
    const booleanValue = e.target.value === 'true';
    setRespectSentenceBoundary(booleanValue);
  };

  const handleErrorHandlingStrategyChange = (option) => {
    setErrorHandlingStrategy(option?.value);
  };

  const handleMaxNoOfTriesChange = (e) => {
    setMaxNoOfTries(parseInt(e.target.value));
  };

  const handleCloseSnackBar = () => {
    setIsSnackbarOpen(false);
    navigate(`${PATHS.FILE_COLLECTIONS_PAGE}/${fileCollectionId}`);
  };

  const handleToggleSecretKeyVisibility = () => {
    setShowSecretKey((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleAccessKeyVisibility = () => {
    setShowAccessKey((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmitButtonClick = async () => {
    const dataSourceObject = {
      name: dataSourceName,
      description: dataSourceDescription,
      endpointType,
      active: isActive,
      endpointParameters: [
        {
          name: 'bucketNameOrArn',
          value: bucketName,
        },
        {
          name: 'region',
          value: region,
        },
        {
          name: 'accessKey',
          value: accessKey,
        },
        {
          name: 'secretKey',
          value: secretKey,
        },
        {
          name: 'delay',
          value: delay,
        },
      ],
      processingParameters: {
        maxMessagesPerPoll,
        textExtractionStrategy,
        normalizeNewLines,
        normalizeWhiteSpaces,
        removeHeaderFooter,
        headerFooterMinChars,
        headerFooterMaxChars,
        textChunkingStrategy,
        minChunkWords,
        maxChunkWords,
        respectSentenceBoundary,
        errorHandlingStrategy,
        maxNoOfTries,
      },
    };

    try {
      const response = await adminApi.addDataSource(
        dataSourceObject,
        fileCollectionId,
      );
      setIsSnackbarOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageBackground>
      <AppContentCard>
        <div>
          <BoxGrid>
            <Typography variant="h4" gutterBottom>
              {t('addDataSourcePage.addDataSourceHeading')}
            </Typography>
          </BoxGrid>

          <DataSourceMeta
            dataSourceName={dataSourceName}
            handleDataSourceNameChange={handleDataSourceNameChange}
            dataSourceDescription={dataSourceDescription}
            handleDataSourceDescriptionChange={
              handleDataSourceDescriptionChange
            }
            isActive={isActive}
            handleIsActiveChange={handleIsActiveChange}
            endpointType={endpointType}
            handleEndpointTypeChange={handleEndpointTypeChange}
          />

          <DataSourceConfigurationDetails
            bucketName={bucketName}
            handleBucketNameChange={handleBucketNameChange}
            region={region}
            handleRegionChange={handleRegionChange}
            accessKey={accessKey}
            handleAccessKeyChange={handleAccessKeyChange}
            secretKey={secretKey}
            handleSecretKeyChange={handleSecretKeyChange}
            delay={delay}
            handleDelayChange={handleDelayChange}
            showSecretKey={showSecretKey}
            handleToggleSecretKeyVisibility={handleToggleSecretKeyVisibility}
            showAccessKey={showAccessKey}
            handleToggleAccessKeyVisibility={handleToggleAccessKeyVisibility}
          />

          <DataSourceProcessingParameters
            maxMessagesPerPoll={maxMessagesPerPoll}
            handleMaxMessagesPerPollChange={handleMaxMessagesPerPollChange}
            normalizeNewLines={normalizeNewLines}
            handleNormalizeNewLinesChange={handleNormalizeNewLinesChange}
            textExtractionStrategy={textExtractionStrategy}
            handleTextExtractionStrategyChange={
              handleTextExtractionStrategyChange
            }
            removeHeaderFooter={removeHeaderFooter}
            handleRemoveHeaderFooterChange={handleRemoveHeaderFooterChange}
            headerFooterMinChars={headerFooterMinChars}
            handleHeaderFooterMinCharsChange={handleHeaderFooterMinCharsChange}
            headerFooterMaxChars={headerFooterMaxChars}
            handleHeaderFooterMaxCharsChange={handleHeaderFooterMaxCharsChange}
            textChunkingStrategy={textChunkingStrategy}
            handleTextChunkingStrategyChange={handleTextChunkingStrategyChange}
            minChunkWords={minChunkWords}
            handleMinChunkWordsChange={handleMinChunkWordsChange}
            maxChunkWords={maxChunkWords}
            handleMaxChunkWordsChange={handleMaxChunkWordsChange}
            normalizeWhiteSpaces={normalizeWhiteSpaces}
            handleNormalizeWhiteSpacesChange={handleNormalizeWhiteSpacesChange}
            respectSentenceBoundary={respectSentenceBoundary}
            handleRespectSentenceBoundaryChange={
              handleRespectSentenceBoundaryChange
            }
            errorHandlingStrategy={errorHandlingStrategy}
            handleErrorHandlingStrategyChange={
              handleErrorHandlingStrategyChange
            }
            maxNoOfTries={maxNoOfTries}
            handleMaxNoOfTriesChange={handleMaxNoOfTriesChange}
          />

          <SubmitButtonGrid>
            <SubmitButtonGroup
              onSubmit={handleSubmitButtonClick}
              hasCancelButton={false}
            />
          </SubmitButtonGrid>
          <Grid>
            <SnackBar
              open={isSnackbarOpen}
              close={handleCloseSnackBar}
              displayMessage={t('addDataSourcePage.successMessage')}
            />
          </Grid>
        </div>
      </AppContentCard>
    </PageBackground>
  );
};

export default AddDataSourcePage;
