/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

const { REACT_APP_PATH_ADMIN_API_URL, REACT_APP_TENANT_ID } = process.env;

export const ADMIN_API_URL = REACT_APP_PATH_ADMIN_API_URL;
export const TENANT_ID = REACT_APP_TENANT_ID;
