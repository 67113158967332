/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Box,
  Divider,
  Grid,
  ListItemText,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';

/**
 * Toolbar with X margin
 */
export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
}));

/**
 * Flexible grid for logo
 */
export const LogoGrid = styled(Grid)({
  display: 'flex',
});

/**
 * Grid to wrap items for md+ screens
 */
export const MediumGridWrap = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

/**
 * Grid to wrap items for mobile screens
 */
export const MobileGridWrap = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

/**
 * Name in bold weight
 */
export const UserName = styled(Typography)({
  fontWeight: 'bold',
});

/**
 * Box with padding for display of user name
 */
export const NameBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

/**
 * avatar and buttons
 */
export const AvatarAndButtonBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-evenly',
}));

/**
 * Button text with padding
 */
export const PaddingTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const ItemText = styled(ListItemText)({
  display: 'flex',
  justifyContent: 'center',
  textDecoration: 'underline',
});

/**
 * ListItemText with padding
 */
export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  padding: theme.spacing(1),
}));

/**
 * Divider with bottom margin
 */
export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

/**
 * Box with flex display
 */
export const FlexBox = styled(Box)({
  display: 'flex',
});
