/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { React, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import PageBackground from '../../components/app.pageBackground';
import DataSources from './dataSources';
import ProcessingParameter from './processingParameter';
import FileCollectionDetails from './fileCollectionDetails';

import { GridItems } from './fileCollection.styled';
import AppContentCard from '../../components/app.contentCard';
import adminApi from '../../services/apiConfig';

const FileCollectionPage = () => {
  const [fileCollection, setFileCollection] = useState({});
  const [error, setError] = useState(null);
  const { fileCollectionId } = useParams();

  const getFileCollectionById = async (id) => {
    try {
      const response = await adminApi.getFileCollectionById(id);
      setFileCollection(response.data);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getFileCollectionById(fileCollectionId);
  }, []);

  return (
    <PageBackground>
      <AppContentCard>
        <Grid Container>
          <GridItems item>
            <FileCollectionDetails fileCollection={fileCollection} />
          </GridItems>
          <GridItems item>
            <ProcessingParameter fileCollection={fileCollection} />
          </GridItems>
          <GridItems item xs={12}>
            <DataSources fileCollectionID={fileCollectionId} />
          </GridItems>
        </Grid>
      </AppContentCard>
    </PageBackground>
  );
};

FileCollectionPage.propTypes = {
  row: PropTypes.object,
};

export default FileCollectionPage;
