/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import PropTypes from 'prop-types';
import { Paper, TextField } from '@mui/material';
import { StyledAutocomplete } from './optionsDropdown.styled';

const OptionsDropdown = ({ label, options, value, onChange, defaultValue }) => (
  <StyledAutocomplete
    value={value}
    onChange={(event, newValue) => {
      onChange(newValue);
    }}
    defaultValue={defaultValue}
    PaperComponent={(props) => <Paper {...props} style={{ borderRadius: 0 }} />}
    options={options}
    renderInput={(params) => <TextField {...params} label={label} />}
  />
);

OptionsDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OptionsDropdown;
