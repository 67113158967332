/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography, Grid } from '@mui/material';
import RadioButtonGroup from '../../components/radioButton';
import OptionsDropdown from '../../components/optionsDropdown';
import {
  FieldValueGrid,
  BoxGrid,
  KeyTypography,
  StyledFieldGrid,
  StyledTextField,
} from './addDataSource.styled';
const DataSourceMeta = ({
  dataSourceName,
  handleDataSourceNameChange,
  dataSourceDescription,
  handleDataSourceDescriptionChange,
  isActive,
  handleIsActiveChange,
  endpointType,
  handleEndpointTypeChange,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <BoxGrid container spacing={2}>
        <FieldValueGrid container item xs={12}>
          <Grid item xs={12} sm={3.7}>
            <KeyTypography>
              {t('addDataSourcePage.dataSourceName')}{' '}
            </KeyTypography>
          </Grid>
          <StyledFieldGrid item xs={12} sm={8.3}>
            <StyledTextField
              xs={12}
              sm={9}
              value={dataSourceName}
              placeholder="Data-source-name"
              onChange={handleDataSourceNameChange}
            />
          </StyledFieldGrid>
        </FieldValueGrid>

        <FieldValueGrid container item xs={12}>
          <Grid item xs={12} sm={3.7}>
            <KeyTypography>
              {t('addDataSourcePage.dataSourceDescription')}
            </KeyTypography>
          </Grid>
          <StyledFieldGrid item xs={12} sm={8.3}>
            <StyledTextField
              multiline
              value={dataSourceDescription}
              placeholder="Data-source-description"
              onChange={handleDataSourceDescriptionChange}
            />
          </StyledFieldGrid>
        </FieldValueGrid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.active')}</KeyTypography>
            </Grid>
            <StyledFieldGrid xs={12} sm={8.3}>
              <RadioButtonGroup
                options={[
                  { value: true, label: 'True' },
                  { value: false, label: 'False' },
                ]}
                onChange={handleIsActiveChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>
                {t('addDataSourcePage.dataSourceType')}
              </KeyTypography>
            </Grid>
            <StyledFieldGrid xs={12} sm={8.3}>
              <OptionsDropdown
                options={[
                  { value: 'AWS', label: 'AWS' },
                  { value: 'Azure Blob', label: 'Azure Blob' },
                ]}
                onChange={handleEndpointTypeChange}
              />
            </StyledFieldGrid>
          </FieldValueGrid>
        </Grid>
      </BoxGrid>
    </Box>
  );
};

export default DataSourceMeta;
