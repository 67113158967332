/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Grid, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RadioButtonGroup from '../../components/radioButton';
import OptionsDropdown from '../../components/optionsDropdown';
import { PaddingTypography } from './addFileCollection.styled';
import {
  ERROR_HANDLING_STRATEGIES,
  TEXT_CHUNKING_STRATEGIES,
  TEXT_EXTRACTION_STRATEGIES,
  TRUE_OR_FALSE,
} from '../../constants/processingOptions';
import { formFields } from '../../constants/formFields';
import { ParameterValues } from '../../constants/parameterValues';

const ProcessingParameters = ({
  handleProcessingParameterChange,
  formValues,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} paddingX={20} paddingTop={5}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {t('addFileCollectionPage.processingParameters')}
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <PaddingTypography variant="subtitle1">
            {t('addFileCollectionPage.maxMsgPerPoll')}
          </PaddingTypography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            margin="normal"
            defaultValue={200}
            onChange={(e) =>
              handleProcessingParameterChange(
                formFields.MAX_MSGS_PER_POLL,
                parseInt(e.target.value, 10),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.normalizeNewLines')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <RadioButtonGroup
          options={TRUE_OR_FALSE}
          defaultValue={true}
          onChange={(e) =>
            handleProcessingParameterChange(
              formFields.NORMALIZE_NEW_LINES,
              Boolean(e.target.value),
            )
          }
        />
      </Grid>

      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.textExtractionStrategy')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <OptionsDropdown
          options={TEXT_EXTRACTION_STRATEGIES}
          value={TEXT_EXTRACTION_STRATEGIES[0]}
          onChange={(option) =>
            handleProcessingParameterChange(
              formFields.TEXT_EXTRACTION_STRATEGY,
              option.value,
            )
          }
        />
      </Grid>
      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.removeHeaderFooter')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <RadioButtonGroup
          options={TRUE_OR_FALSE}
          defaultValue={true}
          onChange={(e) =>
            handleProcessingParameterChange(
              formFields.REMOVE_HEADER_FOOTER,
              e.target.value,
            )
          }
        />
      </Grid>
      {formValues.processingParameters.removeHeaderFooter === true && (
        <>
          <Grid item xs={4}>
            <PaddingTypography variant="subtitle1">
              {t('addFileCollectionPage.minChar')}
            </PaddingTypography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              defaultValue={3}
              onChange={(e) =>
                handleProcessingParameterChange(
                  formFields.MIN_CHAR,
                  parseInt(e.target.value, 10),
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <PaddingTypography variant="subtitle1">
              {t('addFileCollectionPage.maxChar')}
            </PaddingTypography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              defaultValue={30}
              onChange={(e) =>
                handleProcessingParameterChange(
                  formFields.MAX_CHAR,
                  parseInt(e.target.value, 10),
                )
              }
            />
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.textChunkingStrategy')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <OptionsDropdown
          options={TEXT_CHUNKING_STRATEGIES}
          defaultValue={TEXT_CHUNKING_STRATEGIES[0]}
          onChange={(option) =>
            handleProcessingParameterChange(
              formFields.TEXT_CHUNKING_STRATEGY,
              option.value,
            )
          }
        />
      </Grid>
      {formValues.processingParameters.textChunkingStrategy !==
        ParameterValues.TEXT_CHUNKING_STRATEGIES.NONE && (
        <>
          <Grid item xs={4}>
            <PaddingTypography variant="subtitle1">
              {t('addFileCollectionPage.minChunk')}
            </PaddingTypography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              defaultValue={30}
              onChange={(e) =>
                handleProcessingParameterChange(
                  formFields.MIN_CHUNK,
                  parseInt(e.target.value, 10),
                )
              }
            />
          </Grid>
          <Grid item xs={4}>
            <PaddingTypography variant="subtitle1">
              {t('addFileCollectionPage.maxChunk')}
            </PaddingTypography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              type="number"
              variant="outlined"
              fullWidth
              margin="normal"
              defaultValue={300}
              onChange={(e) =>
                handleProcessingParameterChange(
                  formFields.MAX_CHUNK,
                  parseInt(e.target.value, 10),
                )
              }
            />
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.normalizeWhiteSpaces')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <RadioButtonGroup
          options={TRUE_OR_FALSE}
          defaultValue={true}
          onChange={(e) =>
            handleProcessingParameterChange(
              formFields.NORMALIZE_WHITE_SPACES,
              Boolean(e.target.value),
            )
          }
        />
      </Grid>

      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.respectSentenceBoundary')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <RadioButtonGroup
          options={TRUE_OR_FALSE}
          defaultValue={true}
          onChange={(e) =>
            handleProcessingParameterChange(
              formFields.RESPECT_SENTENCE_BOUNDARY,
              Boolean(e.target.value),
            )
          }
        />
      </Grid>
      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.errorHandlingStrategy')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <OptionsDropdown
          options={ERROR_HANDLING_STRATEGIES}
          onChange={(option) =>
            handleProcessingParameterChange(
              formFields.ERROR_HANDLING_STRATEGY,
              option.value,
            )
          }
          defaultValue={ERROR_HANDLING_STRATEGIES[0]}
        />
      </Grid>
      <Grid item xs={4}>
        <PaddingTypography variant="subtitle1">
          {t('addFileCollectionPage.maxTries')}
        </PaddingTypography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          type="number"
          variant="outlined"
          fullWidth
          margin="normal"
          defaultValue={1}
          onChange={(e) =>
            handleProcessingParameterChange(
              formFields.MAX_NO_OF_TRIES,
              parseInt(e.target.value, 10),
            )
          }
        />
      </Grid>
    </Grid>
  );
};

ProcessingParameters.propTypes = {
  handleProcessingParameterChange: PropTypes.func,
  formValues: PropTypes.object,
};

export default ProcessingParameters;
