const formDefaults = {
  name: '',
  description: '',
  scope: 'u',
  processingParameters: {
    maxMessagesPerPoll: 200,
    textExtractionStrategy: 'SIMPLE_EXTRACTION',
    normalizeNewLines: true,
    normalizeWhiteSpaces: true,
    removeHeaderFooter: true,
    headerFooterMinChars: 3,
    headerFooterMaxChars: 30,
    textChunkingStrategy: 'PASSAGE',
    minChunkWords: 30,
    maxChunkWords: 300,
    respectSentenceBoundary: false,
    errorHandlingStrategy: 'LOG_MARK_FORGET',
    maxNoOfTries: 1,
  },
};

export default formDefaults;
