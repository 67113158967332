import React, { useState } from 'react';
import { TextField, Grid, Typography } from '@mui/material';
import PageBackground from '../../components/app.pageBackground';
import AppContentCard from '../../components/app.contentCard';
import RadioButtonGroup from '../../components/radioButton';
import SubmitButtonGroup from '../../components/submitButtonGroup';
import { useTranslation } from 'react-i18next';
import formDefaults from './formDefaults';
import { formFields } from '../../constants/formFields';
import adminApi from '../../services/apiConfig';
import ProcessingParameters from './processingParameters.component';
import { CenteredBox, PaddingTypography } from './addFileCollection.styled';
import { SCOPE_PARAMETERS } from '../../constants/processingOptions';

const NewFileCollectionFormData = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(formDefaults);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleProcessingParameterChange = (field, value) => {
    setFormValues((prevValues) => {
      if (field === formFields.REMOVE_HEADER_FOOTER) {
        const booleanValue = value === 'true';

        return {
          ...prevValues,
          processingParameters: {
            ...prevValues.processingParameters,
            headerFooterMinChars: 3,
            headerFooterMaxChars: 30,
            [field]: booleanValue,
          },
        };
      } else {
        return {
          ...prevValues,
          processingParameters: {
            ...prevValues.processingParameters,
            [field]: value,
          },
        };
      }
    });
  };

  const handleSave = async () => {
    try {
      const response = await adminApi.addFileCollection(formValues);
    } catch (error) {
      console.error('Error saving file collection:', error);
    }
  };
  return (
    <PageBackground>
      <AppContentCard>
        <form>
          <Grid container spacing={2} paddingX={20} paddingTop={5}>
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom>
                {t('addFileCollectionPage.heading')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t('addFileCollectionPage.fileCollectionName')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('addFileCollectionPage.namePlaceholder')}
                variant="outlined"
                fullWidth
                required
                margin="normal"
                onChange={(e) =>
                  handleInputChange(formFields.NAME, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t('addFileCollectionPage.description')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                minRows={10}
                sx={{ width: '100%' }}
                type="text"
                required
                label={t('addFileCollectionPage.descriptionPlaceholder')}
                onChange={(e) =>
                  handleInputChange(formFields.DESCRIPTION, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <PaddingTypography variant="subtitle1">
                {t('addFileCollectionPage.scope')}
              </PaddingTypography>
            </Grid>
            <Grid item xs={8}>
              <RadioButtonGroup
                options={SCOPE_PARAMETERS}
                defaultValue="u"
                onChange={(e) =>
                  handleInputChange(formFields.SCOPE, e.target.value)
                }
              />
            </Grid>
          </Grid>

          <ProcessingParameters
            handleProcessingParameterChange={handleProcessingParameterChange}
            formValues={formValues}
          />
          <CenteredBox>
            <SubmitButtonGroup onSubmit={handleSave} />
          </CenteredBox>
        </form>
      </AppContentCard>
    </PageBackground>
  );
};

export default NewFileCollectionFormData;
