/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  BodyTypography,
  ButtonGrid,
  HomeGrid,
  PrimaryButton,
  TitleTypography,
} from './home.page.styled';
import * as PATHS from '../../constants/path';
import PageBackground from '../../components/app.pageBackground';

/**
 * Home page
 */
const HomePage = () => {
  const { t } = useTranslation();
  return (
    <PageBackground>
      <HomeGrid container spacing={4}>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <TitleTypography variant="h2">
            {t('homePage.welcomeHeading')}
          </TitleTypography>
        </Grid>
        <Grid item xs={12} md={6}>
          <BodyTypography variant="body1">
            <Trans
              i18nKey="homePage.applicationText"
              components={{ 1: <br /> }}
            />
          </BodyTypography>
        </Grid>
        <Grid container item xs={12} md={4}>
          <ButtonGrid item xs={12}>
            <PrimaryButton
              variant="contained"
              component={Link}
              to={PATHS.FILE_COLLECTIONS_PAGE}
            >
              {t('homePage.buttons.fileCollections')}
            </PrimaryButton>
          </ButtonGrid>
          <ButtonGrid item xs={12}>
            <PrimaryButton
              variant="contained"
              component={Link}
              to={PATHS.ROLES_PAGE}
            >
              {t('homePage.buttons.roles')}
            </PrimaryButton>
          </ButtonGrid>
        </Grid>
      </HomeGrid>
    </PageBackground>
  );
};

export default HomePage;
