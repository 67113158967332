/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import adminApi from './apiConfig';

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
  const [fileCollections, setFileCollections] = useState([]);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getFileCollections = async () => {
    try {
      const response = await adminApi.getFileCollections();
      setFileCollections(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteFileCollectionById = async (fileCollectionID) => {
    try {
      await adminApi.deleteFileCollectionById(fileCollectionID);

      setFileCollections((prevFileCollections) =>
        prevFileCollections.filter((item) => item.id != fileCollectionID),
      );
    } catch (error) {
      setError(error);
    }
  };

  const getRoles = async () => {
    try {
      const response = await adminApi.getRoles();
      setRoles(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteRoleById = async (roleId) => {
    try {
      await adminApi.deleteRoleById(roleId);

      setRoles((prevRole) => prevRole.filter((item) => item.id != roleId));
    } catch (error) {
      setError(error);
    }
  };

  const contextValue = {
    fileCollections,
    error,
    getFileCollections,
    deleteFileCollectionById,
    getRoles,
    deleteRoleById,
    loading,
    roles,
  };

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

const useApiContext = () => {
  return useContext(ApiContext);
};

ApiProvider.propTypes = {
  children: PropTypes.node,
};

export { ApiProvider, useApiContext };
