/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { ParameterValues } from './parameterValues';

export const TRUE_OR_FALSE = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const TEXT_EXTRACTION_STRATEGIES = [
  {
    value: ParameterValues.TEXT_EXTRACTION_STRATEGIES.SIMPLE,
    label: 'Simple Extraction',
  },
];

export const TEXT_CHUNKING_STRATEGIES = [
  { value: ParameterValues.TEXT_CHUNKING_STRATEGIES.PASSAGE, label: 'Passage' },
  {
    value: ParameterValues.TEXT_CHUNKING_STRATEGIES.SENTENCE,
    label: 'Sentence',
  },
  { value: ParameterValues.TEXT_CHUNKING_STRATEGIES.WORD, label: 'Word' },
  { value: ParameterValues.TEXT_CHUNKING_STRATEGIES.NONE, label: 'None' },
];

export const ERROR_HANDLING_STRATEGIES = [
  {
    value: ParameterValues.ERROR_HANDLING_STRATEGIES.LOG_MARK_FORGET,
    label: 'Log Mark Forget',
  },
  {
    value: ParameterValues.ERROR_HANDLING_STRATEGIES.LOG_MARK_RETRY_NEXT,
    label: 'Log Mark Retry Next',
  },
];

export const SCOPE_PARAMETERS = [
  { value: ParameterValues.SCOPE.USER, label: 'User' },
  { value: ParameterValues.SCOPE.ORGANISATION, label: 'Organisation' },
];
