/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { styled, Button, ButtonGroup } from '@mui/material';

export const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '& .MuiButton-root': {
    borderColor: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
}));

export const StyledCancelButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(20),
  color: theme.palette.text.primary,
  border: '1px solid',
  cursor: 'pointer',
}));

export const StyledSubmitButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.white,
  backgroundColor: theme.palette.primary.main,
  width: theme.spacing(20),
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    transform: 'scale(1)',
  },
}));
