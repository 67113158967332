/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { createTheme } from '@mui/material/styles';

let { palette } = createTheme();

palette = {
  ...palette,
  primary: {
    main: '#003E51',
  },
  secondary: {
    main: '#E5E5E5',
    light: '#DDDDDD',
    dark: '#000000',
  },
  text: {
    primary: '#000000',
    secondary: '#666666',
    light: '#999999',
    white: '#FFFFFF',
    caption: '#333333',
    controls: '#333333',
    disabled: '#DDDDDD',
    blue: {
      light: '#3AA5C6',
    },
  },
  whiteBackground: {
    main: '#FFFFFF',
  },
  background: {
    main: '#F3F3F3',
    light: '#D9D9D9',
    grey: {
      light: '#E3E3E3',
    },
  },
};

export default palette;
