/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Divider, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledMenu } from './itemsMenu.styled';

/**
 * edit and delete option menu for grids
 */
const ItemsMenu = ({
  anchorEl,
  open,
  handleClose,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem onClick={handleEdit} disableRipple>
        <Edit />
        {t('itemsMenu.edit')}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleDelete} disableRipple>
        <Delete />
        {t('itemsMenu.delete')}
      </MenuItem>
    </StyledMenu>
  );
};

ItemsMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default ItemsMenu;
