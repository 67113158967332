/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, styled } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import BackgroundSvg from '../../assets/background-svg';

export const BackgroundBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  minHeight: '100%',
  backgroundImage: `url(data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<BackgroundSvg />),
  )})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '130px',
}));
