/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundBox } from './app.pageBackground.styled';
import AppBreadcrumbs from '../app.breadcrumbs';

const PageBackground = (props) => {
  return (
    <BackgroundBox>
      <AppBreadcrumbs />
      {props.children}
    </BackgroundBox>
  );
};

PageBackground.propTypes = {
  children: PropTypes.node,
};

export default PageBackground;
