/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
   * Unauthorized copying of this file, via any medium is strictly prohibited
   * Proprietary and confidential
   * See file LICENSE.txt for full license details.
   
   */

import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  HeadingGrid,
  TitleTypography,
  ValueTypography,
  KeyTypography,
  StyledRowGrid,
  BoxGrid,
  GridContainer,
} from './fileCollection.styled';

const ProcessingParameter = ({ fileCollection }) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <GridContainer item container xs={12}>
        <HeadingGrid item xs={12}>
          <TitleTypography variant="h3">
            {t('fileCollectionPage.processingParameter.header')}
          </TitleTypography>
        </HeadingGrid>
      </GridContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BoxGrid container>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.maxMessagesPerPoll',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.maxMessagesPerPoll}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.textExtractionStrategy',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.textExtractionStrategy}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.normalizeNewLines',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.normalizeNewLines
                    ? 'True'
                    : 'False'}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.normalizeWhiteSpaces',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.normalizeWhiteSpaces
                    ? 'True'
                    : 'False'}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.removeHeaderFooter',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.removeHeaderFooter
                    ? 'True'
                    : 'False'}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.headerFooterMinChars',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.headerFooterMinChars}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.headerFooterMaxChars',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.headerFooterMaxChars}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.textChunkingStrategy',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.textChunkingStrategy}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.minChunkWords',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.minChunkWords}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.maxChunkWords',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.maxChunkWords}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.respectSentenceBoundary',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.respectSentenceBoundary
                    ? 'True'
                    : 'False'}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.errorHandlingStrategy',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.errorHandlingStrategy}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t(
                    'fileCollectionPage.processingParameter.processingParameterKeyStrings.maxNoOfTries',
                  )}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection?.processingParameters?.maxNoOfTries}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
          </BoxGrid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ProcessingParameter.propTypes = {
  fileCollection: PropTypes.object,
};

export default ProcessingParameter;
