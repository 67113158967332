/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import React from 'react';
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

const RadioButtonGroup = ({
  heading,
  options,
  selectedValue,
  onChange,
  defaultValue,
}) => {
  return (
    <FormControl component="fieldset">
      <RadioGroup
        row
        aria-label={heading}
        name={heading}
        value={selectedValue}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
