/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Alert, Typography, styled } from '@mui/material';

export const CenteredTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const PaddingTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const SpacedAlert = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1),
}));
