/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
   * Unauthorized copying of this file, via any medium is strictly prohibited
   * Proprietary and confidential
   * See file LICENSE.txt for full license details.
   
   */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as PATHS from '../../constants/path';
import {
  HeadingGrid,
  TitleTypography,
  ValueTypography,
  KeyTypography,
  StyledButton,
  StyledRowGrid,
  BoxGrid,
} from './fileCollection.styled';
const FileCollectionDetails = ({ fileCollection }) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <BoxGrid container xs={12}>
        <HeadingGrid item xs={12} sm={7}>
          <TitleTypography variant="h3">
            {t('fileCollectionPage.header')}
          </TitleTypography>
        </HeadingGrid>
        <HeadingGrid item xs={4} lg={3} xl={3}>
          <StyledButton
            variant="contained"
            component={Link}
            to={PATHS.ADD_FILE_COLLECTION_PAGE}
          >
            {t('common.edit')}
          </StyledButton>
        </HeadingGrid>
      </BoxGrid>
      <Grid container spacing={3} marginTop={0.1}>
        <Grid item xs={12}>
          <BoxGrid container>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t('fileCollectionPage.fileCollectionKeyStrings.name')}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection.name}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t('fileCollectionPage.fileCollectionKeyStrings.description')}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {fileCollection.description}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
            <StyledRowGrid item container xs={12}>
              <Grid item xs={12} md={4.5} sm={12} lg={4}>
                <KeyTypography variant="h5">
                  {t('fileCollectionPage.fileCollectionKeyStrings.scope')}
                </KeyTypography>
              </Grid>
              <Grid item xs={12} md={7.5} sm={12} lg={8}>
                <ValueTypography variant="h5">
                  {t('fileCollectionPage.scope.' + fileCollection.scope)}
                </ValueTypography>
              </Grid>
            </StyledRowGrid>
          </BoxGrid>
        </Grid>
      </Grid>
    </Grid>
  );
};
FileCollectionDetails.propTypes = {
  fileCollection: PropTypes.object,
};
export default FileCollectionDetails;
