/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

/**
 * router link in primary color
 */
export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
