/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Menu } from '@mui/icons-material';
import {
  Avatar,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import {
  AvatarAndButtonBox,
  NameBox,
  PaddingTypography,
  UserName,
} from './app.navbar.styled';
import { stringAvatar } from '../../utils/avatarMaker';

/**
 * User details as shown in navbar for medium or larger screens
 */
const MdScreenUserDetails = ({ tenant, handleSignIn, handleMenuOpen }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  /**
   * show different navigation msg as per auth status
   */
  const handleAuthNavigation = () => {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return (
          <>
            <Typography variant="h5">
              {t('navbar.authStatus.signinSilent')}
            </Typography>
            <LinearProgress />
          </>
        );
      case 'signoutRedirect':
        return (
          <>
            <Typography variant="h5">
              {t('navbar.authStatus.signoutRedirect')}
            </Typography>
            <LinearProgress />
          </>
        );
      case 'undefined':
        return (
          <>
            <Typography variant="h5" color={'error'}>
              {t('navbar.authStatus.undefined')}
            </Typography>
            <LinearProgress />
          </>
        );
      default:
        return (
          <Typography variant="h5" color={'error'}>
            {t('navbar.authStatus.undefined')}
          </Typography>
        );
    }
  };
  return (
    <>
      <NameBox>
        {auth.isLoading && auth.activeNavigator === undefined && (
          <Typography variant="h5">{t('navbar.authStatus.loading')}</Typography>
        )}

        {auth.isAuthenticated && (
          <>
            <UserName variant="h5" color={'primary'}>
              {auth.user.profile.name}
            </UserName>
          </>
        )}
        {!auth.isAuthenticated && handleAuthNavigation()}

        <PaddingTypography variant="caption">{tenant}</PaddingTypography>
      </NameBox>
      <AvatarAndButtonBox>
        {auth.isAuthenticated ? (
          <>
            <Avatar
              variant="square"
              {...stringAvatar(auth?.user?.profile.name)}
            />
            <IconButton onClick={handleMenuOpen}>
              <Menu />
            </IconButton>
          </>
        ) : (
          <Button variant="contained" onClick={handleSignIn}>
            {t('navbar.button.signin')}
          </Button>
        )}
      </AvatarAndButtonBox>
    </>
  );
};

MdScreenUserDetails.propTypes = {
  tenant: PropTypes.string,
  handleSignIn: PropTypes.func,
  handleMenuOpen: PropTypes.func,
};

export default MdScreenUserDetails;
