/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import { MoreHorizOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BottomMarginBox } from './fileCollections.page.styled';
import * as PATHS from '../../constants/path';
import PageBackground from '../../components/app.pageBackground';
import ItemsMenu from '../../components/itemsMenu';
import DataGridTable from '../../components/datagridTable';
import ActionsDialog from '../../components/actionsDialog';
import { useApiContext } from '../../services/apiContext';
import LinearLoading from '../../components/linearLoading';

const FileCollectionsPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteFileCollectionId, setDeleteFileCollectionId] = useState();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    getFileCollections,
    fileCollections,
    deleteFileCollectionById,
    loading,
  } = useApiContext();

  useEffect(() => {
    getFileCollections();
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDeleteFileDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteDialog(true);
    setDeleteFileCollectionId(row.id);
  };

  const handleRowClick = (row) => {
    navigate(`${PATHS.FILE_COLLECTIONS_PAGE}/${row.id}`);
  };

  const handleCloseDeleteFileDialog = () => {
    setOpenDeleteDialog(false);
    handleClose();
  };

  const handleDeleteFileCollection = async () => {
    try {
      await deleteFileCollectionById(deleteFileCollectionId);
      getFileCollections();
      handleCloseDeleteFileDialog();
    } catch (error) {
      console.error('Delete Error:', error);
    }
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip title={t('fileCollectionsPage.tooltips.action')} arrow>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClick(e);
            }}
            disableRipple
          >
            <MoreHorizOutlined />
          </Button>
        </Tooltip>
        <ItemsMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleDelete={(e) => handleOpenDeleteFileDialog(e, props.row)}
        />
      </>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: t('fileCollectionsPage.columns.name'),
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'description',
      headerName: t('fileCollectionsPage.columns.description'),
      minWidth: 300,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'actions',
      headerName: t('fileCollectionsPage.columns.actions'),
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  const fileCollectionsData = fileCollections ? fileCollections : [];

  return (
    <PageBackground>
      <BottomMarginBox>
        {loading ? (
          <LinearLoading />
        ) : (
          <DataGridTable
            row={fileCollectionsData}
            column={columns}
            pageSize={5}
            rowHeightAuto
            handleRow={handleRowClick}
            header={t('fileCollectionsPage.header')}
            isAddButtonNeeded
            path={PATHS.ADD_FILE_COLLECTION_PAGE}
          />
        )}

        <ActionsDialog
          openDialog={openDeleteDialog}
          action={t('fileCollectionsPage.actionsDialog.action')}
          type={t('fileCollectionsPage.actionsDialog.type')}
          closeDialog={handleCloseDeleteFileDialog}
          handleAction={handleDeleteFileCollection}
        />
      </BottomMarginBox>
    </PageBackground>
  );
};

FileCollectionsPage.propTypes = {
  row: PropTypes.object,
};

export default FileCollectionsPage;
