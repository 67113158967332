/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';
import { AppBar, Grid } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MobileUserDetails from './mobileUserDetails';
import MdScreenUserDetails from './mdScreenUserDetails';
import ProfileMenuItems from './profileMenuItems';
import {
  LogoGrid,
  MediumGridWrap,
  MobileGridWrap,
  StyledToolbar,
} from './app.navbar.styled';
import { ReactComponent as SpeciphicLogo } from '../../assets/speciphic-logo.svg';
import * as PATHS from '../../constants/path';
import { DEFAULT_TENANT } from '../../constants/tenant';

/**
 * Navbar with logo, tenant and user details
 */
const AppNavbar = React.forwardRef((props, ref) => {
  const auth = useAuth();
  const tenant = props.tenant ? props.tenant : DEFAULT_TENANT;
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  useEffect(() => {
    if (window.location.pathname === PATHS.HOME_PAGE) {
      auth.signinSilent();
    }
  }, []);

  const handleSignOut = () => {
    auth.signoutRedirect();
  };

  const handleSignIn = () => {
    auth.signinRedirect();
  };

  return (
    <AppBar ref={ref} position="sticky" color="whiteBackground">
      <StyledToolbar disableGutters>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <LogoGrid item xs={8} sm={6} md={4}>
            <Link to={PATHS.HOME_PAGE}>
              <SpeciphicLogo
                width="100%"
                height="100%"
                style={{ maxWidth: '40%' }}
              />
            </Link>
          </LogoGrid>
          <MediumGridWrap item>
            <MdScreenUserDetails
              tenant={tenant}
              handleSignIn={handleSignIn}
              handleMenuOpen={handleMenuOpen}
            />
          </MediumGridWrap>
          <MobileGridWrap item>
            <MobileUserDetails
              handleSignIn={handleSignIn}
              handleMenuOpen={handleMenuOpen}
            />
          </MobileGridWrap>
        </Grid>
        <ProfileMenuItems
          menuAnchor={menuAnchor}
          handleMenuClose={handleMenuClose}
          tenant={tenant}
          handleSignOut={handleSignOut}
        />
      </StyledToolbar>
    </AppBar>
  );
});

AppNavbar.propTypes = {
  tenant: PropTypes.string,
};

AppNavbar.displayName = 'AppNavbar';

export default AppNavbar;
