/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

/**
 * creates avatar using user name with relative bg color for the avatar.
 */
export const stringAvatar = (name) => {
  /**
   * get relative color to the name string
   */
  const stringToColor = (string) => {
    let hash = 0;

    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const nameParts = name.split(' ');
  if (nameParts.length >= 2) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${nameParts[0][0]}${nameParts[1][0]}`,
    };
  } else {
    // if the name doesn't have two words
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name[0], // use of the first character of the name
    };
  }
};
