/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { StyledChip } from './statusChip.styled';

/**
 * Chip to denote active OR inactive status
 * @param {Object} props - Component props
 * @param {string} props.variant - success, error
 * @returns {JSX.Element}
 */
const StatusChip = ({ variant }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const variants = {
    success: {
      color: t('statusChip.color.success'),
      label: t('statusChip.label.active'),
      backgroundColor: theme.palette.success.main,
    },
    error: {
      color: t('statusChip.color.error'),
      label: t('statusChip.label.inactive'),
      backgroundColor: theme.palette.error.main,
    },
  };

  const chipProps = variants[variant];

  // null for unsupported variants
  if (!chipProps) {
    return null;
  }

  return (
    <StyledChip
      icon={<Circle color={chipProps.color} fontSize="small" />}
      label={chipProps.label}
      backgroundColor={chipProps.backgroundColor}
    />
  );
};

StatusChip.propTypes = {
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
};

export default StatusChip;
