/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
   * Unauthorized copying of this file, via any medium is strictly prohibited
   * Proprietary and confidential
   * See file LICENSE.txt for full license details.
   
   */

import { styled, Typography, Grid, Button } from '@mui/material';

export const ButtonGrid = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
}));

export const BoxGrid = styled(Grid)(({ theme }) => ({
  direction: 'row',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  spacing: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  direction: 'column',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  marginBottom: theme.spacing(4),
}));

export const StyledFlexButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  width: '41%',
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.text.primary,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: 20,
  },
}));

export const HeadingGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

export const StyledRowGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(1.5),
  display: 'flex',
}));

export const GridItems = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(7),
}));

export const HorizontalLineGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(1),
}));

export const ValueTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.blue.light,
}));

export const KeyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));
