/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { React, useState, useEffect } from 'react';
import { Grid, Button, Tooltip } from '@mui/material';
import DataGridTable from '../../components/datagridTable';
import ActionsDialog from '../../components/actionsDialog';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import * as PATHS from '../../constants/path';
import adminApi from '../../services/apiConfig';
import ItemsMenu from '../../components/itemsMenu';
import { MoreHorizOutlined } from '@mui/icons-material';
import LinearLoading from '../../components/linearLoading';
import GetDataSourceColumns from './dataSourceColumns';

const DataSources = ({ fileCollectionID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDataSourceId, setDeleteDataSourceId] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const open = Boolean(anchorEl);

  const getDataSources = async (fileCollectionID) => {
    try {
      const response = await adminApi.getDataSources(fileCollectionID);
      setDataSources(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteDataSourceById = async (fileCollectionID, dataSourceId) => {
    try {
      await adminApi.deleteDataSourceById(fileCollectionID, dataSourceId);

      setDataSources((prevDataSources) =>
        prevDataSources.filter((item) => item.id != dataSourceId),
      );
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getDataSources(fileCollectionID);
  }, []);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteDataSourceDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteDialog(true);
    setDeleteDataSourceId(row.id);
  };

  const handleRowClick = (row) => {
    navigate(`${PATHS.FILE_COLLECTIONS_PAGE}/${row.id}`);
  };

  const handleDeleteDataSourceDialog = () => {
    setOpenDeleteDialog(false);
    handleClose();
  };

  const handleDeleteDataSource = async () => {
    try {
      await deleteDataSourceById(fileCollectionID, deleteDataSourceId);
      getDataSources(fileCollectionID);
      handleDeleteDataSourceDialog();
    } catch (error) {
      console.error('Delete Error:', error);
    }
  };

  const actionColumn = (props) => {
    return (
      <>
        <Tooltip title={t('fileCollectionsPage.tooltips.action')} arrow>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleMenuClick(e);
            }}
            disableRipple
          >
            <MoreHorizOutlined />
          </Button>
        </Tooltip>
        <ItemsMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleDelete={(e) => deleteDataSourceDialog(e, props.row)}
        />
      </>
    );
  };
  const columns = GetDataSourceColumns({ actionColumn });

  return (
    <Grid container>
      {loading ? (
        <LinearLoading />
      ) : (
        <Grid item xs={12}>
          <Grid container>
            <DataGridTable
              row={dataSources}
              column={columns}
              pageSize={5}
              rowHeightAuto
              handleRow={handleRowClick}
              header={t('fileCollectionPage.dataSourceConnectedTo.heading')}
              searchPlaceholder={t(
                'fileCollectionPage.dataSourceConnectedTo.saerchBar.placeholder',
              )}
              isAddButtonNeeded
              path={`${PATHS.FILE_COLLECTIONS_PAGE}/${fileCollectionID}/${PATHS.ADD_DATA_SOURCE_PAGE}`}
            />
            <ActionsDialog
              openDialog={openDeleteDialog}
              action={t(
                'fileCollectionPage.dataSourceConnectedTo.actionsDialog.action',
              )}
              type={t(
                'fileCollectionPage.dataSourceConnectedTo.actionsDialog.type',
              )}
              closeDialog={handleDeleteDataSourceDialog}
              handleAction={handleDeleteDataSource}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

DataSources.propTypes = {
  rowID: PropTypes.string,
  getDataSources: PropTypes.func,
};

export default DataSources;
