export const ParameterValues = {
  TEXT_EXTRACTION_STRATEGIES: {
    SIMPLE: 'SIMPLE_EXTRACTION',
  },
  TEXT_CHUNKING_STRATEGIES: {
    PASSAGE: 'PASSAGE',
    SENTENCE: 'SENTENCE',
    WORD: 'WORD',
    NONE: 'NONE',
  },
  ERROR_HANDLING_STRATEGIES: {
    LOG_MARK_FORGET: 'LOG_MARK_FORGET',
    LOG_MARK_RETRY_NEXT: 'LOG_MARK_RETRY_NEXT',
  },
  SCOPE: {
    USER: 'u',
    ORGANISATION: 'o',
  },
};
