export const formFields = {
  NAME: 'name',
  DESCRIPTION: 'description',
  SCOPE: 'scope',
  MAX_MSGS_PER_POLL: 'maxMessagesPerPoll',
  NORMALIZE_NEW_LINES: 'normalizeNewLines',
  TEXT_EXTRACTION_STRATEGY: 'textExtractionStrategy',
  REMOVE_HEADER_FOOTER: 'removeHeaderFooter',
  MIN_CHAR: 'headerFooterMinChars',
  MAX_CHAR: 'headerFooterMaxChars',
  TEXT_CHUNKING_STRATEGY: 'textChunkingStrategy',
  MIN_CHUNK: 'minChunkWords',
  MAX_CHUNK: 'maxChunkWords',
  NORMALIZE_WHITE_SPACES: 'normalizeWhiteSpaces',
  RESPECT_SENTENCE_BOUNDARY: 'respectSentenceBoundary',
  ERROR_HANDLING_STRATEGY: 'errorHandlingStrategy',
  MAX_NO_OF_TRIES: 'maxNoOfTries',
};
