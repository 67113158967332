/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, Paper, Typography, styled } from '@mui/material';

/**
 * Box with margin
 */
export const FooterBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
}));

/**
 * Styled paper with fixed position
 */
export const FooterPaper = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
}));

/**
 * Footer flexday details box
 */
export const FlexdayContentBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  paddingRight: theme.spacing(3),
  float: 'right',
  display: 'flex',
  flexDirection: 'row',
}));

/**
 * Typography with  padding
 */
export const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
}));

/**
 * Logo box with width
 */
export const LogoBox = styled(Box)({
  width: '30%',
});
