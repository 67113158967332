/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import { useTranslation } from 'react-i18next';
import { Grid, Typography, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  BoxGrid,
  KeyTypography,
  GridContainer,
  FieldValueGrid,
  StyledTextField,
} from './addDataSource.styled';

const DataSourceConfigurationDetails = ({
  bucketName,
  handleBucketNameChange,
  region,
  handleRegionChange,
  accessKey,
  handleAccessKeyChange,
  secretKey,
  handleSecretKeyChange,
  delay,
  handleDelayChange,
  showSecretKey,
  handleToggleAccessKeyVisibility,
  showAccessKey,
  handleToggleSecretKeyVisibility,
}) => {
  const { t } = useTranslation();

  return (
    <GridContainer>
      <BoxGrid>
        <Typography variant="h4" gutterBottom>
          {t('addDataSourcePage.configurationDetailsHeading')}
        </Typography>
      </BoxGrid>
      <BoxGrid container spacing={2}>
        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.bucketName')}</KeyTypography>
            </Grid>
            <Grid item xs={12} sm={8.3}>
              <StyledTextField
                value={bucketName}
                onChange={handleBucketNameChange}
              />
            </Grid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.region')}</KeyTypography>
            </Grid>
            <Grid item xs={12} sm={8.3}>
              <StyledTextField value={region} onChange={handleRegionChange} />
            </Grid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container item>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.accessKey')}</KeyTypography>
            </Grid>
            <Grid item xs={12} sm={8.3}>
              <StyledTextField
                type={showAccessKey ? 'text' : 'password'}
                value={accessKey}
                onChange={handleAccessKeyChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleAccessKeyVisibility}
                        edge="end"
                      >
                        {showAccessKey ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.secretKey')}</KeyTypography>
            </Grid>
            <Grid item xs={12} sm={8.3}>
              <StyledTextField
                type={showSecretKey ? 'text' : 'password'}
                value={secretKey}
                onChange={handleSecretKeyChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleToggleSecretKeyVisibility}
                        edge="end"
                      >
                        {showSecretKey ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </FieldValueGrid>
        </Grid>

        <Grid item xs={12}>
          <FieldValueGrid container>
            <Grid item xs={12} sm={3.7}>
              <KeyTypography>{t('addDataSourcePage.delay')}</KeyTypography>
            </Grid>
            <Grid item xs={12} sm={8.3}>
              <StyledTextField
                type="number"
                value={delay}
                onChange={handleDelayChange}
              />
            </Grid>
          </FieldValueGrid>
        </Grid>
      </BoxGrid>
    </GridContainer>
  );
};

export default DataSourceConfigurationDetails;
