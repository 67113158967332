/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Avatar, ListItem, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import {
  PaddingTypography,
  StyledDivider,
  StyledListItemText,
} from './app.navbar.styled';
import { stringAvatar } from '../../utils/avatarMaker';

/**
 * handles the profile menu with sign out option
 */
const ProfileMenuItems = ({
  menuAnchor,
  handleMenuClose,
  tenant,
  handleSignOut,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  return (
    <>
      {auth?.isAuthenticated && (
        <Menu
          anchorEl={menuAnchor}
          open={Boolean(menuAnchor)}
          onClose={handleMenuClose}
        >
          <ListItem>
            <Avatar
              variant="square"
              {...stringAvatar(auth?.user?.profile.name)}
            />
            <StyledListItemText
              primary={auth?.user?.profile.name}
              secondary={tenant}
              primaryTypographyProps={{ variant: 'h5' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
          <StyledDivider />
          <MenuItem onClick={handleSignOut}>
            <Logout fontSize="small" />
            <PaddingTypography variant="body1">
              {t('navbar.button.signout')}
            </PaddingTypography>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

ProfileMenuItems.propTypes = {
  menuAnchor: PropTypes.bool,
  handleMenuClose: PropTypes.func,
  tenant: PropTypes.string,
  handleSignOut: PropTypes.func,
};

export default ProfileMenuItems;
